import { defineNuxtPlugin } from '#app'

import {
  createTonConnectUIProvider,
  TonConnectButton,
  TonConnectUIContext,
  TonConnectUIOptionsContext,
} from 'ton-ui-vue'

export default defineNuxtPlugin((nuxtApp) => {
  const { tonConnectUI, setOptions } = createTonConnectUIProvider({
    manifestUrl:
      'https://www.projectj-tcg.com/tonconnect-manifest.json',
  })

  nuxtApp.vueApp.component('TonConnectButton', TonConnectButton)
  nuxtApp.vueApp.provide(TonConnectUIContext, tonConnectUI)
  nuxtApp.vueApp.provide(TonConnectUIOptionsContext, setOptions)
})
